<template>
  <a-card>
    <a-page-header :title="'设置托管账套'" />
    <!--<div class="red mb-20">需要与广告主确认后再进行操作，若平台账套有投放中广告计划无法变更</div>-->
    <div class="mb-20">广告主：{{ name || '-' }}</div>

    <a-form-model ref="form" :model="form" v-bind="layout">
      <a-row>
        <a-col :span="10">
          <a-form-model-item label="平台帐套ID">
            <a-input-number
              v-model="form.adPlatformAccountId"
              class="input"
              :maxLength="30"
              :precision="0"
              placeholder="请输入平台帐套ID"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="flex">
          <a-button type="primary" @click="handleSubmit">提交托管</a-button>
          <a-button style="margin: 0 14px" @click="handleRest">重置</a-button>
          <a-button type="primary" @click="handleSearch">查询</a-button>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="dataList"
      :row-key="(record, index) => index"
      :pagination="false"
    >
      <div slot="action" slot-scope="text, record">
        <span class="underline" @click="handleHosting(record, '取消托管')" v-if="record.advertiserId == advertiserId"
          >取消托管</span
        >
        <a-button type="link" @click="handleHosting(record, '设为托管')" v-if="!record.advertiserId"
          >设为托管
        </a-button>
      </div>
    </a-table>

    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      advertiserId: this.$route.query.advertiserId,
      adPlatformType: this.$route.query.adPlatformType,
      name: this.$route.query.name,
      loading: false,
      columns: [
        {
          align: 'center',
          title: '广告平台',
          dataIndex: 'adPlatformName',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '平台账套名',
          dataIndex: 'adPlatformAccountName',
          customRender(text) {
            return text || '-';
          },
          width: '20%',
        },
        {
          align: 'center',
          title: '平台账套ID',
          dataIndex: 'adPlatformAccountId',
          customRender(text) {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '巨量引擎用户ID',
          dataIndex: 'authUserId',
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '巨量引擎用户邮箱',
          dataIndex: 'email',
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '巨量引擎用户名',
          dataIndex: 'displayName',
          customRender: (text) => {
            return text || '-';
          },
        },
        {
          align: 'center',
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      form: {
        advertiserId: this.$route.query.advertiserId,
      },
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let params = {
        adPlatformAccountId: this.form.adPlatformAccountId,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      this.$api.core.pphtManage.getTrusteeAccountList(params).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          this.dataList = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.dataList = [];
          this.pagination.total = 0;
          console.error(`获取列表失败${res}`);
        }
      });
    },
    handleRest() {
      this.form.adPlatformAccountId = '';
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getDataList();
    },
    handleSearch() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getDataList();
    },
    handleHosting(record, type) {
      let that = this;
      this.$confirm({
        title: `确认要${type}？`,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          let params = {
            advertiserId: that.$route.query.advertiserId,
            id: record.id,
            isTrustee: record.trusteeStatus == 2 ? false : true,
          };
          that.$api.core.pphtManage.setTrusteeAccountStatus(params).then((res) => {
            if (res.code == 200) {
              that.$message.success(`${type}成功`);
              that.getDataList();
            } else {
              that.$message.error(`${type}失败，${res.message}`);
            }
          });
        },
      });
    },
    handleSubmit() {
      if (!this.form.adPlatformAccountId) {
        this.$message.error('请填写平台帐套ID');
        return;
      }
      this.$api.core.pphtManage.submitTrusteeAccountStatus(this.form).then((res) => {
        if (res.code == 200) {
          this.$message.success('提交托管成功');
          this.getDataList();
        } else {
          this.$message.error(`提交托管失败，${res.message}`);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
  padding-left: 0;
  padding-right: 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.underline {
  color: #40a9ff;
  cursor: pointer;
  text-decoration: underline;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.input {
  width: 110%;
}
</style>
